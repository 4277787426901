import {Canvas, Image, Textbox} from 'fabric';

document.addEventListener('click', function(event) {
    if (event.target.id === 'cdownload') {
        let btn = document.getElementById('cdownload');
        const canvas = document.getElementById('certcanvas').fabric;
        canvas.discardActiveObject();
        canvas.renderAll();
        btn.href = document.getElementById('certcanvas').toDataURL('image/png');
    }
});

let txtHandler = function(e) {
    let btn = document.getElementById('cdownload');
    btn.href = document.getElementById('certcanvas').toDataURL('image/png');
};

export function completioncert() {
    document.addEventListener('turbo:load', function() {
        if (document.getElementById('certcanvas')) {
            const canvas = new Canvas('certcanvas');
            document.getElementById('certcanvas').fabric = canvas;
            Image.fromURL(document.getElementById('urls').dataset['cert'])
                .then((img =>
                    img.set({
                        lockMovementX: true,
                        lockMovementY: true,
                        selectable: false
                    })
                ))
                .then(function(img) {
                    img.scaleToHeight(566);
                    img.scaleToWidth(800);
                    return img;
                })
                .then((img =>
                    canvas.add(img)
                ))
                .then(function() {
                    let text = new Textbox('Click and type student name',
                        {
                            borderColor: '#fa6912',
                            cornerStrokeColor: '#5700ef',
                            left: 200,
                            top: 163,
                            fontSize: 30,
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            textAlign: 'center',
                            fill: '#04274F',
                            width: 450
                        });
                    text.setControlsVisibility({
                        mt: false, // middle top disable
                        mb: false, // midle bottom
                    });
                    canvas.setActiveObject(text);
                    text.enterEditing();
                    text.selectAll();
                    canvas.add(text);
                    canvas.bringObjectToFront(text);
                });
            canvas.on('text:changed', txtHandler);
            canvas.on('object:modified', txtHandler);
            canvas.on('mouse:up', txtHandler);
        }
    });
}

import * as bootstrap from 'bootstrap';
import './style.scss';
import * as Turbo from '@hotwired/turbo';
import { initSearchSetup } from './search';
import 'animate.css';
initSearchSetup();

import { completioncert } from './cert';
completioncert();

import * as Sentry from '@sentry/browser';
import { knownErrors } from './sentry_errors';

Sentry.init({
    dsn: 'https://473bb4ebe928155973106c4c47c28c77@o4505687081484288.ingest.sentry.io/4505687086661632',
    ignoreErrors: [...knownErrors],

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/climatesmart\.ie\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export const animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {once: true});
    });


// Use event delegation to register the mayor click handler
// Slightly more convoluted because we have to check the parent element for the class too
document.addEventListener('click', function(event) {
    let mayor;
    try {
        if (event.target.classList.contains('mayor')) {
            mayor = event.target;
        } else if (event.target.parentElement.classList.contains('mayor')) {
            mayor = event.target.parentElement;
        }
    } catch (error) {
        mayor = null;
    }
    if (mayor) {
        event.preventDefault();
        const name = mayor.firstElementChild;
        const fname = mayor.dataset.fname;
        // I have no idea why this is such a pain, but this is necessary to parse the bio strings
        // const bio = JSON.parse(`"${mayor.dataset.bio}"`);
        document.getElementById('mayorheader').innerHTML = name.innerHTML;
        // document.getElementById('mayorbody').innerHTML = `${fname}${bio}`;
        const chosen = document.getElementById('chosenmayor');
        const alignment = document.getElementById('alignment');
        const submit = document.getElementById('submit');
        chosen.value = mayor.dataset.mayorid;
        alignment.value = mayor.dataset.alignment;
        submit.classList.remove('disabled');
        submit.disabled = false;
        const choicetext = submit.dataset.choicetext;
        submit.value = `${choicetext} ${fname}`;
    }
}, false);

document.addEventListener('click', function(event) {
    if (event.target.classList.contains('hprint')) {
        event.preventDefault();
        window.print();
    }
});


document.addEventListener('turbo:load', function() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
    const edlang = document.getElementById('editlanguage');
    if (edlang) {
        edlang.addEventListener('change', (event) => {
            for(let el of document.getElementsByClassName('slanguage')) {
                el.value = event.target.value;
                const questions = document.getElementById('question');
                // Get all option elements
                const allOptions = questions.querySelectorAll('option');
                const english = Array.from(allOptions).filter(option => {
                    const value = parseFloat(option.value);
                    return value >= 1 && value <= 66;
                });
                const irish = Array.from(allOptions).filter(option => {
                    const value = parseFloat(option.value);
                    return value >= 67 && value <= 200;
                });
                if (parseFloat(event.target.value) === 1) {
                    english.forEach((elem) => {
                        elem.style.display = 'block';
                        elem.removeAttribute('disabled');
                    });
                    irish.forEach((elem) => {
                        elem.style.display = 'none';
                        elem.setAttribute('disabled', true);
                    });
                } else if (parseFloat(event.target.value) === 2) {
                    english.forEach((elem) => {
                        elem.style.display = 'none';
                        elem.setAttribute('disabled', true);
                    });
                    irish.forEach((elem) => {
                        elem.style.display = 'block';
                        elem.removeAttribute('disabled');
                    });
                }
            }
            for(let el of document.getElementsByClassName('lsd')) {
                event.target.value !== '__None' ? el.removeAttribute('disabled') : el.disabled = true;
            }
        });
    }
});
